import React from "react";

export class AboutPage extends React.Component {
  render() {
    return (
      <div>
        {/* Page Content */}
        <div className="container">
          {/* Page Heading/Breadcrumbs */}
          <h1 className="mt-4 mb-3">About Freshman to Fresh Grad</h1>
          {/* Intro Content */}
          <div className="row">
            <div className="col-lg-6">
              <p>
                Whether you're an incoming Freshman or an outgoing Fresh Grad,
                you have some important decisions to make that will shape the
                rest of your career. If you're anything like us, you've spent
                countless hours researching what to study, where to study, where
                to work, and what to work on.
              </p>
              <p>
                We set out to create a centralized hub where people could access
                the information they needed in a few simple clicks. We believe
                that information is power, so we wish to give users a picture of
                the path to becoming a software engineer. We hope to eliminate
                the headache of searching and cross-referencing of information
                for those looking to dive into software.
              </p>
            </div>
          </div>
          {/* /.row */}
          {/* Team Members */}
          <h2>Our Team</h2>
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/amir_pic.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Amir Mostafavi</h4>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Back-end: API
                  </h6>
                  <p>
                    Senior, In the 5 year BS/MS program for CS and HCI. Interned
                    at Google last summer and coming back for another
                    internship.
                  </p>
                  <p id="Amir Mostafavi" className="card-text">
                    Unit Tests: 10
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/connor_pic.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Connor Hubbard</h4>
                  <h6 className="card-subtitle mb-2 text-muted">Front-end</h6>
                  <p>
                    Your local rock climbing geek. <br />
                    Eat. Sleep. Climb. Code.
                  </p>
                  <p id="Connor Hubbard" className="card-text">
                    Unit Tests: 0
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/chandler_pic.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Chandler Ochs</h4>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Back-end: DB
                  </h6>
                  <p>
                    Little tike teeball champion of '98. <br />
                    CS is my side hustle.
                  </p>
                  <p id="Chandler Ochs" className="card-text">
                    Unit Tests: 10
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* another row*/}
          <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/dillan_pic.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Dillan Holland</h4>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Back-end: DB
                  </h6>
                  <p>
                    Graduating senior, plays lots of video games, been working
                    as a developer for 4 year, proficient in C# and SQL
                  </p>
                  <p id="Dillan Holland" className="card-text">
                    Unit Tests: 0
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/zac_pic.png"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Zachary May</h4>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Back-end: API
                  </h6>
                  <p>
                    Junior studying CS and Math.
                    <br />
                    Hits: board games, tennis balls
                    <br />
                    Misses: Math, occasionally tennis balls
                  </p>
                  <p id="Zachary May" className="card-text">
                    Unit Tests: 48
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/sooyong_pic.JPG"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">Sooyong Lee</h4>
                  <h6 className="card-subtitle mb-2 text-muted">Frontend</h6>
                  <p>
                    Trying my best.
                    <br />I like to play the clarinet.
                  </p>
                  <p id="Sooyong Lee" className="card-text">
                    Unit Tests: 10
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h2>Project Statistics</h2>
          <li>Unit Tests: 78</li>
          <p id="total_commit" />
          <p id="total_issue" />
          <h2>Tools</h2>
          {/* /.row */}
          <div className="row">
            <div className="col-lg-3 mb-4">
              <div className="card h-100 text-center">
                <img className="card-img-top" src="./images/aws.png" alt="" />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://aws.amazon.com/">Amazon Web Services</a>
                  </h4>
                  <p className="card-text">Server for hosting</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/postman.jpg"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://documenter.getpostman.com/view/10532101/Szf3bWPq?version=latest">
                      Our Postman
                    </a>
                  </h4>
                  <p className="card-text">API design and documentation</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card h-100 text-center">
                <img
                  className="card-img-top"
                  src="./images/gitlab.png"
                  alt=""
                />
                <div className="card-body">
                  <h4 className="card-title">
                    <a href="https://gitlab.com/amirmst/freshman-2-freshgrad">
                      Our Gitlab
                    </a>
                  </h4>
                  <p className="card-text">Contains source code</p>
                </div>
              </div>
            </div>
          </div>
          <h2>Data Sources</h2>
          <ul>
            <a href="https://www.uplead.com/">
              UpLead
              <br />
            </a>
            <a href="https://jobs.github.com/api">
              Git Jobs
              <br />
            </a>
            <a href="https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates">
              ArcGis
              <br />
            </a>
            <a href="https://api.data.gov/ed/collegescorecard/v1/schools">
              US Government College Info API
              <br />
            </a>
            <a href="https://www.numbeo.com/api/cities">
              Numbeo
              <br />
            </a>
            <a href="https://www.numbeo.com/api/indicies">
              Numbeo City Info
              <br />
            </a>
          </ul>
        </div>
        {/* /.container */}
        {/* Bootstrap core JavaScript */}
      </div>
    );
  }
  componentDidMount() {
    function getInfo() {
      let usernames = [
        "amirmst",
        "chandlerochs",
        "hubchub",
        "dillan0321",
        "sooyonglee",
        "zacharymay",
      ];
      let names = [
        "Amir Mostafavi",
        "Chandler Ochs",
        "Connor Hubbard",
        "Dillan Holland",
        "Sooyong Lee",
        "Zachary May",
      ];
      var total_commit = 0;
      var total_issue = 0;
      var dillan_commits = 0;
      var amir_commits = 0;
      var connor_commits = 0;

      fetch(
        "https://gitlab.com/api/v4/projects/17022161/repository/contributors"
      )
        .then((response) => response.json())
        .then((myJson) => {
          for (let i = 0; i < 10; i++) {
            var curName = myJson[i].name;
            console.log(curName);
            var numCommits = myJson[i].commits;
            console.log(numCommits);
            if (curName == "Dillan Holland") {
              dillan_commits += numCommits;
            } else if (curName == "amirmst" || curName == "Amir Mostafavi") {
              amir_commits += numCommits;
            } else if (curName == "Connor Hubbard") {
              connor_commits += numCommits;
            } else {
              var commitCount = document.createElement("p");
              commitCount.innerHTML = "Commits: " + numCommits;
              document?.getElementById(curName)?.appendChild(commitCount);
              total_commit += numCommits;
            }
          }
          var dillanCommitCount = document.createElement("p");
          dillanCommitCount.innerHTML = "Commits: " + dillan_commits;
          document
            .getElementById("Dillan Holland")
            ?.appendChild(dillanCommitCount);

          var amirCommitCount = document.createElement("p");
          amirCommitCount.innerHTML = "Commits: " + amir_commits;
          document
            .getElementById("Amir Mostafavi")
            ?.appendChild(amirCommitCount);

          var connorCommitCount = document.createElement("p");
          connorCommitCount.innerHTML = "Commits: " + connor_commits;
          document
            .getElementById("Connor Hubbard")
            ?.appendChild(connorCommitCount);

          total_commit =
            total_commit + dillan_commits + amir_commits + connor_commits;

          var num_commits = document.createElement("li");
          num_commits.innerHTML = total_commit + " commits";
          document?.getElementById("total_commit")?.appendChild(num_commits);
        });

      for (let i = 0; i < names.length; i++) {
        fetch(
          "https://gitlab.com/api/v4/projects/17022161/issues_statistics?author_username=" +
            usernames[i]
        )
          .then((response) => response.json())
          .then((myJson) => {
            var numIssues = myJson.statistics.counts.all;
            var issueCount = document.createElement("p");
            issueCount.innerHTML = "Issues: " + numIssues;
            document?.getElementById(names[i])?.appendChild(issueCount);
            total_issue += numIssues;
            console.log(total_issue);
            if (i == names.length - 1) {
              var num_issues = document.createElement("li");
              num_issues.innerHTML = total_issue + " issues";
              document?.getElementById("total_issue")?.appendChild(num_issues);
            }
          });
      }
    }
    getInfo();
  }
}
export default AboutPage;
