import React from 'react';
import { Link } from 'react-router-dom'

export class CityInstancePage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
  state = {
      info: [],
      comp: [],
      universities: []
  }
  componentDidMount() {
      fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/cities?city='+this.props.match.params.city)
      .then(res => res.json())
      .then((data) => {
        this.setState({info: data})
        console.log(this.state.info)
      })
      .catch(console.log)
      fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/companies/filter?state='+this.props.match.params.city.split("=")[1])
      .then(res => res.json())
      .then((data) => {
        let comp_list = []
        for (var comp in data) {
          comp_list.push(data[comp]['company' as any]);
        }
        this.setState({comp: comp_list})
        console.log(this.state.comp)
      })   
      .catch(console.log)
      fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/universities/filter?state='+this.props.match.params.city.split("=")[1])
        .then((res => res.json()))
        .then((data) => {
            let uni_list = []
            for (var uni in data) {
                uni_list.push((data[uni]['university' as any])+'^'+data[uni]['state']);
            }
            this.setState({universities: uni_list})
            console.log(this.state.universities)
        })
        .catch(console.log)
  }
  render() {
    const companies = this.state.comp.map((company) =>
    <Link to={"/companies/"+company}>
        <li>{company}</li>
    </Link>
    );
    const universities = this.state.universities.map((university: any) =>
    <Link to={"/universities/"+university.split('^')[0]+'&state='+university.split('^')[1]}>
        <li>{university.split('^')[0]}</li>
    </Link>
    );
    return (
      <div className="container">
        {/* Page Heading/Breadcrumbs */}
        <h1 className="mt-4 mb-3">{this.state.info["city" as any]}
        </h1>
        {/* Portfolio Item Row */}
        <div className="row">
          <div className="col-md-8">
            <img className="img-fluid" src={this.state.info["image_url" as any]} alt="" />
          </div>
          <div className="col-md-4">
            <h3 className="my-3">Attributes</h3>
            <ul>
              <li>State: {this.state.info["state" as any]}</li>
              <li>Latitude/Longitude: {parseFloat(this.state.info["latitude" as any]).toFixed(2)},{parseFloat(this.state.info["longitude" as any]).toFixed(2)}</li>
              <li>Crime Index: {parseFloat(this.state.info["crime_index" as any]).toFixed(2)}</li>
              <li>CPI Index: {parseFloat(this.state.info["cpi_index" as any]).toFixed(2)}</li>
              <li>Traffic Time Index (Average time to commute): {parseFloat(this.state.info["traffic_time_index" as any]).toFixed(2)}</li>
              <li>Healthcare Index: {parseFloat(this.state.info["health_care_index" as any]).toFixed(2)}</li>
              <li>Quality of Life Index: {parseFloat(this.state.info["quality_of_life_index" as any]).toFixed(2)}</li>
              <li>Rent Index: {parseFloat(this.state.info["rent_index" as any]).toFixed(2)}</li>
              <li>Population: {this.state.info["population" as any]}</li>
              <li>Companies Nearby:</li>
                  <ul>{companies}</ul>
              <li>Universities Nearby:</li>
                  <ul>{universities}</ul>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
export default CityInstancePage;