import React from 'react';
import { Link } from 'react-router-dom'

export class CompanyInstancePage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
    state = {
        info: [],
        universities: []
    }
    componentDidMount() {
        fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/companies?name='+this.props.match.params.company)
        .then(res => res.json())
        .then((data) => {
          this.setState({info: data})
          console.log(this.state.info)
          fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/universities/filter?state='+this.state.info['state' as any])
          .then((res => res.json()))
          .then((data) => {
              let uni_list = []
              for (var uni in data) {
                  uni_list.push((data[uni]['university' as any])+'^'+data[uni]['state']);
              }
              this.setState({universities: uni_list})
              console.log(this.state.universities)
          })
          .catch(console.log)
        })   
        .catch(console.log)
    }
    render() {
      const universities = this.state.universities.map((university: any) =>
      <Link to={"/universities/"+university.split('^')[0]+'&state='+university.split('^')[1]}>
          <li>{university.split('^')[0]}</li>
      </Link>
      );
      return (
        <div className="container">
          {/* Page Heading/Breadcrumbs */}
          <h1 className="mt-4 mb-3">{this.state.info["company" as any]}
          </h1>
          {/* Portfolio Item Row */}
          <div className="row">
            <div className="col-md-8">
              <img className="img-fluid" src={this.state.info["image_url" as any]} alt="" />
            </div>
            <div className="col-md-4">
              <h3 className="my-3">Attributes</h3>
              <ul>
                <Link to={"/cities/"+this.state.info["city" as any]+"&state="+this.state.info["state" as any]}>
                    <li>City: {this.state.info["city" as any]}</li>
                </Link>
                <li>State: {this.state.info["state" as any]}</li>
                <li>CEO: {this.state.info["ceo" as any]}</li>
                <li>CEO Approval Rating: {this.state.info["ceo_approval_rating" as any]}</li>
                <li>Company Type: {this.state.info["company_type" as any]}</li>
                <li>Glassdor Rating (out of 5): {this.state.info["glassdoor_rating" as any]}</li>
                <li>Industry: {this.state.info["industry" as any]}</li>
                <li>Interviewing Information</li>
                <ul>
                    <li>Interview Difficulty: {this.state.info["interview_difficulty" as any]}</li>
                    <li>Interview from Employee Referral: {this.state.info["interview_from_employee_referral" as any]}</li>
                    <li>Interview from Online Application: {this.state.info["interview_from_online_application" as any]}</li>
                    <li>Interview from Recruiter: {this.state.info["interview_from_recruiter" as any]}</li>
                    <li>Negative Interview Experience: {this.state.info["negative_interview_experience" as any]}</li>
                    <li>Neutral Interview Experience: {this.state.info["neutral_interview_experience" as any]}</li>
                    <li>Positive Interview Experience: {this.state.info["positive_interview_experience" as any]}</li>
                </ul>    
                <li>Recommend to a Friend: {this.state.info["recommend_to_a_friend" as any]}</li>
                <li>Revenue: {this.state.info["revenue" as any]}</li>
                <li>Size: {this.state.info["size" as any]}</li>
                <li>Domain: {this.state.info["website" as any]}</li>
                <li>Year Founded: {this.state.info["year_founded" as any]}</li>
                <li>Universities Nearby:</li>
                    <ul>{universities}</ul>
              </ul>
            </div>
          </div>
        </div>
      )
    }
}
export default CompanyInstancePage;