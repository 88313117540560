import React from 'react';

export class NavBar extends React.Component {
    render() {
        return (
<nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div className="container">
      <a className="navbar-brand" href="/">Freshman to Fresh Grad</a>
      <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="/cities">Cities</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/universities">Universities</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/companies">Companies</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/visualizations">Visualizations</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">About</a>
          </li>
        </ul>
        <form className="form-inline my-2 my-lg-0" action="/search/" method="get">
  <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" name="q"/>
  <button className="btn btn-sm btn-outline-light my-2 my-sm-0" type="submit">Search</button>
</form>
      </div>
    </div>
  </nav>
        )
    }
}
export default NavBar;