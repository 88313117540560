import React from "react";

export class HomePage extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to={0}
                className="active"
              />
              <li data-target="#carouselExampleIndicators" data-slide-to={1} />
              <li data-target="#carouselExampleIndicators" data-slide-to={2} />
            </ol>
            <div className="carousel-inner" role="listbox">
              {/* Slide One - Set the background image for this slide in the line below */}
              <div
                className="carousel-item active"
                style={{ backgroundImage: 'url("./images/newyork.jpg")' }}
              >
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              {/* Slide Two - Set the background image for this slide in the line below */}
              <div
                className="carousel-item"
                style={{ backgroundImage: 'url("./images/columbia.jpg")' }}
              >
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
              {/* Slide Three - Set the background image for this slide in the line below */}
              <div
                className="carousel-item"
                style={{ backgroundImage: 'url("./images/apple.jpg")' }}
              >
                <div className="carousel-caption d-none d-md-block"></div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>
        </header>
        {/* Page Content */}
        <div className="container">
          <div className="text-center">
            <h1 className="my-4">About Freshman to Fresh Grad</h1>
            {/* Features Section */}
            <div className="row">
              <div className="col-sm-12">
                <p>
                  Whether you're an incoming Freshman or an outgoing Fresh Grad,
                  you have some important decisions to make that will shape the
                  rest of your career. If you're anything like us, you've spent
                  countless hours researching what to study, where to study,
                  where to work, and what to work on.
                </p>
                <p>
                  We set out to create a centralized hub where people could
                  access the information they needed in a few simple clicks. We
                  believe that information is power, so we wish to give users a
                  picture of the path to becoming a software engineer. We hope
                  to eliminate the headache of searching and cross-referencing
                  of information for those looking to dive into software.
                </p>
              </div>
            </div>
            {/* /.row */}
            {/* Call to Action Section */}
            <div className="row mb-4">
              <div className="col-sm-12 text-center">
                <a
                  className="btn btn-lg btn-secondary btn-block"
                  href="/universities"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          {/* /.container */}
        </div>
      </div>
    );
  }
}
export default HomePage;
