import React from "react";
import "../js/jquery-global";
import { Link } from "react-router-dom";

export class SearchPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      cities: [],
      universities: [],
      companies: [],
      currentPage: 1,
      todosPerPage: 20,
      upperPageBound: 4,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 4,
    };
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
  }
  componentDidUpdate() {
    $("ul li.active").removeClass("active");
    $("ul li#" + this.state.currentPage).addClass("active");
  }
  handleClick(event: any) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    $("ul li.active").removeClass("active");
    $("ul li#" + listid).addClass("active");
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid: any) {
    let totalPage = Math.ceil(
      this.state.todos.length / this.state.todosPerPage
    );
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  }
  btnIncrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick() {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick() {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  render() {
    const {
      cities,
      universities,
      companies,
      currentPage,
      todosPerPage,
      upperPageBound,
      lowerPageBound,
      isPrevBtnActive,
      isNextBtnActive,
    } = this.state;
    let renderUniversities;
    if (universities === []) {
      renderUniversities = "";
    } else {
      renderUniversities = universities.map((uni: any) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
            <div className="card h-100">
              <Link
                to={
                  "/universities/" +
                  uni["university"] +
                  "&state=" +
                  uni["state"]
                }
              >
                <img
                  className="card-img-top"
                  height="150"
                  src={uni["image_url"]}
                  alt=""
                />
              </Link>
              <div className="card-body">
                <h4 className="card-title">
                  <Link
                    to={
                      "/universities/" +
                      uni["university"] +
                      "&state=" +
                      uni["state"]
                    }
                  >
                    {uni["university"]}
                  </Link>
                </h4>
                <p className="card-text">
                  City: {uni["city"]}
                  <br />
                  CS Ranking: {uni["cs_ranking"]}
                  <br />
                  Graduation Rate:{" "}
                  {parseFloat(uni["graduation_rate"]).toFixed(2)}
                  <br />
                  Undergraduate Population {uni["undergrad_population"]}
                  <br />
                  Admission Rate:{" "}
                  {parseFloat(uni["admission_rate"]).toFixed(2) + "%"}
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
    let renderCompanies;
    if (companies === []) {
      renderCompanies = "";
    } else {
      renderCompanies = companies.map((com: any) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
            <div className="card h-100">
              <Link to={"/companies/" + com["company"]}>
                <img
                  className="card-img-top"
                  height="150"
                  src={com["image_url"]}
                  alt=""
                />
              </Link>
              <div className="card-body">
                <h4 className="card-title">
                  <Link to={"/companies/" + com["company"]}>
                    {com["company"]}
                  </Link>
                </h4>
                <p className="card-text">
                  City: {com["city"]}
                  <br />
                  Industry: {com["industry"]}
                  <br />
                  Domain: {com["website"]}
                  <br />
                  Employees {com["size"]}
                  <br />
                  Year Founded: {com["year_founded"]}
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
    let renderCities;

    if (cities === []) {
      renderCities = "";
    } else {
      renderCities = cities.map((city: any) => {
        return (
          <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
            <div className="card h-100">
              <Link to={"/cities/" + city["city"] + "&state=" + city["state"]}>
                <a href="/#">
                  <img
                    className="card-img-top"
                    height="150"
                    src={city["image_url"]}
                    alt=""
                  />
                </a>
              </Link>
              <div className="card-body">
                <h4 className="card-title">
                  <Link
                    to={"/cities/" + city["city"] + "&state=" + city["state"]}
                  >
                    {city["city"]}
                  </Link>
                </h4>
                <p className="card-text">
                  Population: {city["population"]}
                  <br />
                  Quality of Life Index:{" "}
                  {parseFloat(city["quality_of_life_index"]).toFixed(2)}
                  <br />
                  Rent Index: {parseFloat(city["rent_index"]).toFixed(2)}
                  <br />
                  Crime Index: {parseFloat(city["crime_index"]).toFixed(2)}
                  <br />
                  Traffic Time Index:{" "}
                  {parseFloat(city["traffic_time_index"]).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <=
      Math.ceil(
        (cities.length + universities.length + companies.length) / todosPerPage
      );
      i++
    ) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className="active" id={number + ""}>
            <a href="#" id={number + ""} onClick={this.handleClick}>
              {number}
            </a>
          </li>
        );
      } else if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <li key={number} id={number + ""}>
            <a href="#" id={number + ""} onClick={this.handleClick}>
              {number}
            </a>
          </li>
        );
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = (
        <li className="">
          <a href="#" onClick={this.btnIncrementClick}>
            {" "}
            &hellip;{" "}
          </a>
        </li>
      );
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = (
        <li className="">
          <a href="#" onClick={this.btnDecrementClick}>
            {" "}
            &hellip;{" "}
          </a>
        </li>
      );
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === "disabled") {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <span id="btnPrev"> Prev </span>
        </li>
      );
    } else {
      renderPrevBtn = (
        <li className={isPrevBtnActive}>
          <a href="#" id="btnPrev" onClick={this.btnPrevClick}>
            {" "}
            Prev{" "}
          </a>
        </li>
      );
    }
    let renderNextBtn = null;
    if (isNextBtnActive === "disabled") {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <span id="btnNext"> Next </span>
        </li>
      );
    } else {
      renderNextBtn = (
        <li className={isNextBtnActive}>
          <a href="#" id="btnNext" onClick={this.btnNextClick}>
            {" "}
            Next{" "}
          </a>
        </li>
      );
    }
    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Freshman to Fresh Grad</title>
        {/* Page Content */}
        <div className="container">
          {/* Page Heading/Breadcrumbs */}
          <h1 className="mt-4 mb-3">Cities</h1>
          <h4>Where you reside along your path...</h4>
          <ul>
            <div className="row">{renderCities}</div>
          </ul>
          <h1 className="mt-4 mb-3">Universities</h1>
          <h4>Where your path begins...</h4>
          <ul>
            <div className="row">{renderUniversities}</div>
          </ul>
          <h1 className="mt-4 mb-3">Companies</h1>
          <h4>Where your path will take you...</h4>
          <ul>
            <div className="row">{renderCompanies}</div>
          </ul>
        </div>
      </div>
    );
  }
  componentDidMount() {
    console.log(this.props);

    fetch(
      "https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/cities?q=" +
        this.props.location.search.replace("?q=", "")
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ cities: data });
      })
      .catch(console.log);

    fetch(
      "https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/companies?q=" +
        this.props.location.search.replace("?q=", "")
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ companies: data });
      })
      .catch(console.log);

    fetch(
      "https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/universities?q=" +
        this.props.location.search.replace("?q=", "")
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ universities: data });
        console.log(this.state);
      })
      .catch(console.log);
  }
}
export default SearchPage;
