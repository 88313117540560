import React, { useEffect, useState } from "react";
import "../js/jquery-global";
import UniStateBarGraph from "../components/UniStateBarGraph";
import StateUniCostBarGraph from "../components/StateUniCostBarGraph";
import RestaurantCostPieChart from "../components/RestaurantCostPieChart";
import CompanySizePieChart from "../components/CompanySizePieChart";
import StateRestaurantBubbleChart from "../components/StateRestaurantBubbleChart";
import StateCompanyBubbleChart from "../components/StateCompanyBubbleChart";

export class VisualizationsPage extends React.Component<{}, any> {
  render() {
    return (
      <div>
        {/* Page Content */}
        <div className="container">
          {/* Page Heading/Breadcrumbs */}
          <h1 className="mt-4 mb-3">Visualizations</h1>
          <h2>Our Visualizations</h2>
          <div className="text-center">
            <h4>Number of Universities per State</h4>
            <UniStateBarGraph />
            <p></p>
            <h4>Number of Companies at Different Sizes</h4>
            <CompanySizePieChart />
            <h4>Number of Companies per State</h4>
            <StateCompanyBubbleChart />
          </div>
          <h2>Visualizations for Dev</h2>
          <p></p>
          <div className="text-center">
            <h4>Average Cost of Universities per State</h4>
            <StateUniCostBarGraph />
            <p></p>
            <h4>Number of Restaurants at Various Price Points</h4>
            <RestaurantCostPieChart />
            <p></p>
            <h4>Number of Restaurants per State</h4>
            <StateRestaurantBubbleChart />
          </div>
        </div>
      </div>
    );
  }
}
export default VisualizationsPage;
