import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import CitiesPage from './pages/CitiesPage';
import CityInstancePage from './pages/CityInstancePage';
import UniversitiesPage from './pages/UniversitiesPage';
import CompaniesPage from './pages/CompaniesPage';
import CompanyInstancePage from './pages/CompanyInstancePage';
import UniversityInstancePage from './pages/UniversityInstancePage';
import NavBar from './components/NavBar';
import SearchPage from './pages/SearchPage';
import VisualizationsPage from './pages/VisualizationsPage';

function App() {
  return (
    <div>
      <Router>
        <NavBar/>
        <Switch>
          <Route exact  path='/' component={HomePage}/>
          <Route path='/about' component={AboutPage}/>
          <Route exact path='/cities' component={CitiesPage}/>
          <Route exact path='/cities/:city' component={CityInstancePage}/>
          <Route exact path='/universities' component={UniversitiesPage}/>
          <Route path='/universities/:university' component={UniversityInstancePage}/>
          <Route exact path='/companies' component={CompaniesPage}/>
          <Route path='/companies/:company' component={CompanyInstancePage}/>
          <Route exact path='/search/' component={SearchPage}/>
          <Route exact path='/visualizations' component={VisualizationsPage}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
