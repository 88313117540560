import React, { useEffect, useState } from 'react';
import '../js/jquery-global'
import { Link } from 'react-router-dom'

export class CitiesPage extends React.Component<{}, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      todos: [],
      currentPage: 1,
      todosPerPage: 20,
      upperPageBound: 4,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 4
    }
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
  }
  componentDidUpdate() {
    $("ul li.active").removeClass('active');
    $('ul li#'+this.state.currentPage).addClass('active');
  }
  handleClick(event: any) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#'+listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid: any) {
    let totalPage = Math.ceil(this.state.todos.length / this.state.todosPerPage);
    this.setState({isNextBtnActive: 'disabled'});
    this.setState({isPrevBtnActive: 'disabled'});
    if(totalPage === listid && totalPage > 1){
        this.setState({isPrevBtnActive: ''});
    }
    else if(listid === 1 && totalPage > 1){
        this.setState({isNextBtnActive: ''});
    }
    else if(totalPage > 1){
        this.setState({isNextBtnActive: ''});
        this.setState({isPrevBtnActive: ''});
    }
  }
  btnIncrementClick() {
    this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
    this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid});
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick() {
    this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
    this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid});
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick() {
    if((this.state.currentPage -1)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick() {
    if((this.state.currentPage +1) > this.state.upperPageBound ){
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
  }
  setSortType(sortProperty: string) {
  const todos = this.state.todos;
    const sorted = [...todos].sort((a, b) => b[sortProperty] - a[sortProperty]);
    this.setState({todos: sorted});
  }
  setFilter(state: string) {
    const todos = this.state.todos;
    console.log(state);
    if (state === ("none")) {
      fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/cities')
      .then(res => res.json())
      .then((data) => {
        this.setState({ todos: data})
      })
      .catch(console.log)
    }
    else {
      fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/cities/filter?state='+state)
        .then(res => res.json())
        .then((data) => {
          this.setState({ todos: data})
          console.log(this.state.todos)
        })
        .catch(console.log)
    }
  }
  render() {
    const { todos, currentPage, todosPerPage,upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive } = this.state;
    if (todos === []) {
      return <div />;
    }
    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((city: any) => {
      return (
        <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
        <div className="card h-100">
        <Link to={"/cities/"+city['city']+'&state='+city['state']}>
          <a href="/#"><img className="card-img-top" height="150" src={city['image_url']} alt="" /></a>
          </Link>
          <div className="card-body">
            <h4 className="card-title">
            <Link to={"/cities/"+city['city']+'&state='+city['state']}>
              {city['city']}
            </Link>
            </h4>
            <p className="card-text">Population: {city['population']}<br />Quality of Life Index: {parseFloat(city['quality_of_life_index']).toFixed(2)}<br />Rent Index: {parseFloat(city['rent_index']).toFixed(2)}<br />Crime Index: {parseFloat(city['crime_index']).toFixed(2)}<br />Traffic Time Index : {parseFloat(city['traffic_time_index']).toFixed(2)}</p>
          </div>
        </div>
        </div>
      )
    });
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map(number => {
        if(number === 1 && currentPage === 1){
            return(
                <li key={number} className='active' id={number+''}><a href='#' id={number+''} onClick={this.handleClick}>{number}</a></li>
            )
        }
        else if((number < upperPageBound + 1) && number > lowerPageBound){
            return(
                <li key={number} id={number+''}><a href='#' id={number+''} onClick={this.handleClick}>{number}</a></li>
            )
        }
    });
    let pageIncrementBtn = null;
    if(pageNumbers.length > upperPageBound){
        pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
    }
    let pageDecrementBtn = null;
    if(lowerPageBound >= 1){
        pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
    }
    let renderPrevBtn = null;
    if(isPrevBtnActive === 'disabled') {
        renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev"> Prev </span></li>
    }
    else{
        renderPrevBtn = <li className={isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
    }
    let renderNextBtn = null;
    if(isNextBtnActive === 'disabled') {
        renderNextBtn = <li className={isNextBtnActive}><span id="btnNext"> Next </span></li>
    }
    else{
        renderNextBtn = <li className={isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
    } 
    return (
      <div>
        <title>Freshman to Fresh Grad</title>
        {/* Page Content */}
        <div className="container">
          {/* Page Heading/Breadcrumbs */}
          <h1 className="mt-4 mb-3">Cities
          </h1>
          <h4>Where you reside along your path...</h4>
          <div className="Sorter">
            <select onChange={(e) => this.setSortType(e.target.value)}>
              <option value="crime_index">Sort by Crime Index</option>
              <option value="population">Sort by Population</option>
              <option value="quality_of_life_index">Sort by Quality of Life Index</option>
              <option value="rent_index">Sort by Rent index</option>
              <option value="traffic_time_index">Sort by Traffic Time Index</option>
            </select>
          </div>
          <div className="Filter"> 
            <select name="state" onChange={(e) => this.setFilter(e.target.value)}>
              <option value="none">None</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <p></p>
          <ul>
            <div className="row"> 
              {renderTodos}
            </div>
          </ul>
          <ul id="page-numbers" className="pagination">
            {renderPrevBtn}
            {pageDecrementBtn}
            {renderPageNumbers}
            {pageIncrementBtn}
            {renderNextBtn}
          </ul>
        </div>
      </div>
    )
    }
  componentDidMount() {
    fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/cities')
    .then(res => res.json())
    .then((data) => {
      this.setState({ todos: data})
      console.log(this.state.todos)
    })
    .catch(console.log)
  }
}
export default CitiesPage;