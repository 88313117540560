import React from 'react';
import { Link } from 'react-router-dom'

export class UniversityInstancePage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }
    state = {
        info: [],
        comp: []
    }
    componentDidMount() {
        fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/universities?name='+this.props.match.params.university)
        .then(res => res.json())
        .then((data) => {
          this.setState({info: data})
          console.log(this.state.info)
        })   
        .catch(console.log)
        .catch(console.log)
        fetch('https://pn4phxwahg.execute-api.us-east-1.amazonaws.com/dev/companies/filter?state='+this.props.match.params.university.split("=")[1])
        .then(res => res.json())
        .then((data) => {
          let comp_list = []
          for (var comp in data) {
            comp_list.push(data[comp]['company' as any]);
          }
          this.setState({comp: comp_list})
          console.log(this.state.comp)
        })   
        .catch(console.log)
    }
    render() {
      const companies = this.state.comp.map((company) =>
        <Link to={"/companies/"+company}>
            <li>{company}</li>
        </Link>
        );
        return (
          <div className="container">
            {/* Page Heading/Breadcrumbs */}
            <h1 className="mt-4 mb-3">{this.state.info["university" as any]}
            </h1>
            {/* Portfolio Item Row */}
            <div className="row">
              <div className="col-md-8">
                <img className="img-fluid" src={this.state.info["image_url" as any]} alt="" />
              </div>
              <div className="col-md-4">
                <h3 className="my-3">Attributes</h3>
                <ul>
                  <li>Latitude/Longitude: {parseFloat(this.state.info["latitude" as any]).toFixed(2)}, {parseFloat(this.state.info["longitude" as any]).toFixed(2)}</li>
                  <Link to={"/cities/"+this.state.info["city" as any]+"&state="+this.props.match.params.university.split("=")[1]}>
                    <li>City: {this.state.info["city" as any]}</li>
                  </Link>
                  <li>CS Ranking: {this.state.info["cs_ranking" as any]}</li>
                  <li>Undergrad Population: {this.state.info["undergrad_population" as any]}</li>
                  <li>Gender Demographics:</li>
                  <ul>
                    <li>{parseFloat(this.state.info["male_percentage" as any]).toFixed(2)}% male</li>
                    <li>{parseFloat(this.state.info["female_percentage" as any]).toFixed(2)}% female</li>
                  </ul>
                  <li>Racial Demographics:</li>
                  <ul>
                    <li>{parseFloat(this.state.info["white_percentage" as any]).toFixed(2)}% white</li>
                    <li>{parseFloat(this.state.info["black_percentage" as any]).toFixed(2)}% black</li>
                    <li>{parseFloat(this.state.info["asian_percentage" as any]).toFixed(2)}% asian</li>
                    <li>{parseFloat(this.state.info["hispanic_percentage" as any]).toFixed(2)}% hispanic</li> 
                  </ul>
                  <li>Tuition:</li>
                  <ul>
                    <li>In-State: ${this.state.info["in_state_tuition" as any]}</li>
                    <li>Out of State: ${this.state.info["out_of_state_tuition" as any]}</li>  
                  </ul>
                  <li>Graduation Rate: {parseFloat(this.state.info["graduation_rate" as any]).toFixed(2)}</li>
                  <li>Admission Rate: {parseFloat(this.state.info["admission_rate" as any]).toFixed(2)}%</li>
                  <li>Median Test Scores for Admitted Students:</li>
                  <ul>
                    <li>SAT Median Reading: {this.state.info["sat_median_reading" as any]}</li>
                    <li>SAT Median Math: {this.state.info["sat_median_math" as any]}</li>
                    <li>ACT Median English: {this.state.info["act_median_english" as any]}</li>
                    <li>ACT Median Math: {this.state.info["act_median_math" as any]}</li>
                  </ul>
                  <li>Companies Nearby:</li>
                    <ul>{companies}</ul>
                </ul>
              </div>
            </div>
          </div>
        )
    }
}
export default UniversityInstancePage;