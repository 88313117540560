import React, {Component} from 'react';
import * as d3 from 'd3';
import stateUniCost from '../components/stateUniCost.json'

class UniStateCostBarGraph extends React.Component{
    constructor(props: any){
        super(props);
        this.createBarChart = this.createBarChart.bind(this);
    }
    state = {
        data: stateUniCost,
        xAttr: 'state',
        yAttr: 'avg_annual_cost'
    }

    componentDidMount(){
        this.createBarChart();
    }

    createBarChart(){
        const margin = { top: 50, right: 20, bottom: 100, left: 80};
        const height = 600 - margin.top - margin.bottom;
        const width = 1200 - margin.left - margin.right;

        const svg = d3
            .select("#barChart")
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        const x = d3
            .scaleBand()
            .range([0, width])
            .domain(this.state.data.map((d: { [x: string]: any; }) => d[this.state.xAttr]))
            .padding(0.2);
            svg
            .append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x))
            .selectAll('text')
            .attr('transform', 'translate(-10,0)rotate(-45)')
            .style('text-anchor', 'end');

        const maxValue = Math.max(...this.state.data.map((d: { [x: string]: any; }) => d[this.state.yAttr]));
        const y = d3
            .scaleLinear()
            .domain([0, Math.ceil(maxValue / 10) * 10])
            .range([height, 0]);
            svg.append('g').call(d3.axisLeft(y));

        const bars = svg.selectAll(".bar")
            .data(this.state.data)
            .enter()
            .append("g");

        bars.append("rect")
        .attr("x", function (d: any): any {
            return x(d.state);
        })
        .attr("y", function (d) {
            return y(d.avg_annual_cost);
        })
        .attr('width', x.bandwidth())
        .attr('height', (d: { avg_annual_cost: number | { valueOf(): number; }; }) => height - y(d.avg_annual_cost))

        // x axis label
        svg.append("text")             
            .attr("transform",
                    "translate(" + (width/2) + " ," + 
                                (height + margin.top + 50) + ")")
            .style("text-anchor", "middle")
            .text("States");

        // y axis label
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("Avg Cost for Universities"); 
    }

    render(){
        return <div id="barChart"></div>;
    }

}
export default UniStateCostBarGraph;
